import React from 'react';
import './Avatar.css';
import avatarImage from '../../assets/images/sidzerV2.png'// Importez l'image comme une variable

function Avatar() {
    return (
        <div className="avatar-container">
            <img src={avatarImage} alt="Votre Avatar" className="avatar-image" />
        </div>
    );
}

export default Avatar;
