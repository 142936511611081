import React from 'react';
import './Navbar.css';


function Navbar () {

    return (

        <header className="header">

            <a href="#" className="logo">Logo....</a>

            <input type="checkbox"  id="check" />
            <label htmlFor="check"  className="icons">
                <i className='bx bx-menu' id="menu-icon"></i>
                <i className='bx bx-x' id="close-icon"></i>
            </label>

            <nav className="navbar">

                <a href="#"  style={{ '--i': '0' }}>Accueil</a>
                <a href="#" style={{ '--i': '1' }}>Etude | Expérience</a>
                <a href="#" style={{ '--i': '2' }}>Projet</a>
                <a href="#" style={{ '--i': '3' }}>Contacts</a>
                <a href="#" style={{ '--i': '4' }}>Mon histoire</a>

            </nav>

        </header>

    )

}

export default Navbar