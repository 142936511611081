import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import {pageview} from "./utils/analytics";
import MaintenancePage from "./pages/Maintenance/MaintenancePage";


function App() {
    return (
        <Router>
            <AppContent />
        </Router>
    );
}

function AppContent() {
    const location = useLocation();

    useEffect(() => {
        pageview(location.pathname + location.search);
    }, [location]);

    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<MaintenancePage/>} />
                {/* Ajout d'autres routes ici à l'avenir. */}
            </Routes>
        </div>
    );
}

export default App;