import React, { useState} from 'react';
import './Maintenance.css';
import sunGif from '../../assets/icons8-soleil-unscreen.gif'
import moonGif from '../../assets/icons8-brouillard-de-nuit-unscreen.gif'
import Navbar from "../../components/Navbar/Navbar";
import Avatar from "../../components/Avatar/Avatar";

function MaintenancePage  ()  {

    const [mode, setMode] = useState('light-mode');

    const toggleMode = () => {
        if (mode === 'light-mode') {
            setMode('dark-mode');
            document.body.classList.remove('light-mode');
            document.body.classList.add('dark-mode');
        } else {
            setMode('light-mode');
            document.body.classList.remove('dark-mode');
            document.body.classList.add('light-mode');
        }
    };
    return (
        <div className={`maintenance-container ${mode}`}>
            <Navbar />
            <div className="content-container">
                <h1 className="maintenance-title">Site en cours de développement</h1>
                <p className="maintenance-message">Le site est actuellement en maintenance. Revenez bientôt !</p>
                <Avatar/>
                <button className="maintenance-button-color" onClick={toggleMode}>
                    {mode === "light-mode" ? <img src={moonGif} alt="Moon icon" /> : <img src={sunGif} alt="Sun icon" />}
                </button>
            </div>

        </div>
    );
}

export default MaintenancePage;